
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.modalContent {
  .modalHeader {
    background: color.$primary-color;
    padding: 15px;
    border-radius: 6px 6px 0 0;
    h4 {
      color: color.$white-color;
      font-size: 22px;
      font-weight: 700;
    }
    .closeIcon {
      right: -12px;
      top: -12px;
      width: 26px;
      height: 26px;
      background: variables.$background-lakeshore-sprites 0 -78px / 260px 260px;
      font-size: 0;
      min-width: auto;
      position: absolute;
      padding: 0;
      border: none;
      @include breakpoint('medium') {
        width: 35px;
        height: 35px;
        background: variables.$background-lakeshore-sprites 0 -105px / 350px
          350px;
      }
    }
  }
}

.modalBody {
  label {
    margin-bottom: 5px;
  }
  input[type='email'],
  input[type='password'],
  input[type='text'] {
    margin-bottom: 15px;
    position: relative;
  }
  p {
    font-size: 11px;
    color: color.$black-color;
  }
}

.modalSlideIn {
  animation: slideIn 0.7s forwards;
}

@keyframes slideIn {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}
