// Define your breakpoints
$breakpoints: (
  'small': 576px,
  'medium': 768px,
  'large': 992px,
  'mlarge': 1060px,
  'xlarge': 1200px,
  'xxlarge': 1400px,
  'xxxlarge': 1700px,
);

// Mixin to handle breakpoints
@mixin breakpoint($point) {
  $size: map-get($breakpoints, $point);

  @if $size {
    @media (min-width: $size) {
      @content;
    }
  } @else {
    @warn "Breakpoint '#{$point}' not found in \$breakpoints map.";
  }
}
