$black: #000000;
$black3: #333;
$black4: #252525;
$black-color: #020202;
$black-color-hover: #414141;
$black-shadow: #00000013;
$blue: #174878;
$blue2: #0b53a5;
$blue3: #2d8cab;
$blue4: #1365b1;
$blue-royal: #004677;
$blue-black: #181818;
$cornflower: #6865ff;
$cyan: #00abd0;
$cyan2: #43bbcb;
$celtic-blue: #3398cc;
$celtic-blue-hover: #39b4f4;
$danger-btn-color: #e7000f;
$danger-btn-hover-color: #ff4138;
$fuscia: #f61c6a;
$green: #67ca32;
$green2: #008000;
$green-hover: #73e239;
$gray: #555;
$gray2: #666;
$gray3: #f8f8f8;
$gray4: #808080;
$gray5: #d8d8d8;
$gray6: #777;
$gray7: #adacac;
$gray-f0f0f1: #f0f0f1;
$gray-silver: #999999;
$gray-gainsboro: #ddd;
$green-blue: #0081d0;
$green-blue2: #00adef;
$golden-color: #f39c12;
$light-gray: #cccccc;
$light-gray2: #eeeeee;
$light-blue: #66afe8;
$light-blue2: #3a91c6;
$light-blue-shadow: #66afe999;
$lilac: #d660e9;
$orange: #fa935b;
$orange2: #ff7b33;
$primary-color: #007daa;
$primary-hover-color: #00a4df;
$purple: #9a33cc;
$purple-hover: #b443eb;
$red: #ea2431;
$red2: #ff3334;
$red3: #e23a41;
$red-crimson: #ed1c24;
$red-neon: #e60e0e;
$red-ferrari: #ff5c5d;
$red4: #ce8483;
$ternary-btn-color: #717171;
$ternary-btn-hover-color: #a0a0a0;
$thin-gray: #aaa;
$tangerine: #ffa318;
$tangerine2: #fd9326;
$tangerine3: #f99229;
$tangerine4: #fdd878;
$tangerine2-hover: #ffdc00;
$teal: #008080;
$teal-hover: #039191;
$turquoise: #0acbbf;
$turquoise-hover: #0eddd0;
$white-color: #fff;
$white2: #e2e2e2;
$yellow: #ffc600;
$yellow-hover: #ffdc00;
$yellow2: #fc0;
$green3: #00b422;
$pagination-color: #337ab7;
$heading-bg: #f0f0f0;
$gray: #8e8e8e;
$bgmodal: rgba(0, 0, 0, 0.2);
$grey8: #dfdfdf;
$grey9: #e3e3e3;
$grey10: #555;
$grey11: #444;
$grey1: #848484;
$grey-12: #f6f6f6;
$blue-toggle: #0072bc;
$blue-selection: #027bca;
$light-green: #00b422;
$black-light: #232323;
